import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>404 - Page Not Found</h1>
      <p style={styles.text}>
        Sorry, the page you are looking for does not exist.
      </p>
      <Link to="/" style={styles.link}>
        Go Back to Home
      </Link>
    </div>
  );
};

// Inline styles for the component
const styles = {
  container: {
    textAlign: "center",
    marginTop: "100px",
  },
  header: {
    fontSize: "48px",
    color: "#333",
  },
  text: {
    fontSize: "18px",
    color: "#777",
  },
  link: {
    fontSize: "18px",
    color: "#007bff",
    textDecoration: "none",
    marginTop: "20px",
    display: "inline-block",
  },
};

export default NotFound;
