import React, { Component } from "react";
import { getAuth, signOut } from "firebase/auth";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import logo from "../static/ibtehaz_sign_white_bg_big_1.png";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false, // Track if user is authenticated
    };
  }

  componentDidMount() {
    const auth = getAuth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        // User is logged in
        this.setState({ isAuthenticated: true });
      } else {
        // User is logged out
        this.setState({ isAuthenticated: false });
      }
    });
  }

  handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful
        this.setState({ isAuthenticated: false });
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  render() {
    const { isAuthenticated } = this.state;

    return (
      <nav
        className="navbar navbar-expand-lg navbar-light bg-dark"
        style={{ paddingLeft: "30px", paddingRight: "30px" }}
      >
        <Link className="navbar-brand" to="/" style={{ color: "#fff" }}>
          <img
            src={logo}
            width="100"
            height="50"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Link>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav">
            <Link className="nav-item nav-link" to="/" style={{ color: "#fff" }}>
              Home
            </Link>
            <a
              className="nav-item nav-link"
              href="https://www.linkedin.com/in/ibtehaz/"
              style={{ color: "#fff" }}
            >
              Portfolio
            </a>
            {/* <Link
              className="nav-item nav-link"
              to="/contact"
              style={{ color: "#fff" }}
            >
              Contact
            </Link> */}
            <Link
              className="nav-item nav-link"
              to="/blogs"
              style={{ color: "#fff" }}
            >
              Blogs
            </Link>
            {isAuthenticated ? (
              <>
                <Link
                  className="nav-item nav-link"
                  to="/dashboard"
                  style={{ color: "#fff" }}
                >
                  Dashboard
                </Link>
                <a
                  className="nav-item nav-link"
                  href="#logout"
                  onClick={this.handleLogout}
                  style={{ color: "#fff" }}
                >
                  Logout
                </a>
              </>
            ) : null}
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
