// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDxmy68k-xzGgxee6GRmu60sgv-DSWO2fo",
  authDomain: "ibtehazxyz.firebaseapp.com",
  projectId: "ibtehazxyz",
  storageBucket: "ibtehazxyz.appspot.com",
  messagingSenderId: "714266366904",
  appId: "1:714266366904:web:70fe1a12f026041f63b54d",
  measurementId: "G-P0PT4JPS3C",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

// Export the database and authentication module if needed
export {firebaseApp};
