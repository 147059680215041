import React, { Component } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMessage: "",
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    const auth = getAuth();

    try {
      const userCred = await signInWithEmailAndPassword(auth, email, password);
      const user = userCred.user;

      localStorage.setItem("user", JSON.stringify(user));
      alert("Logged in successfully!");
      this.props.navigate("/dashboard");
    } catch (error) {
      let errorMessage = error.message; // Default Firebase error message

      // Check if it's a structured error message (like the one you provided)
      try {
        const parsedError = JSON.parse(error.message); // Attempt to parse the error as JSON
        if (parsedError && parsedError.error && parsedError.error.message) {
          errorMessage = parsedError.error.message; // Extract "INVALID_LOGIN_CREDENTIALS"
        }
      } catch (e) {
        // If parsing fails, use Firebase's default error message
      }

      this.setState({
        errorMessage: errorMessage,
      });
    }
  };

  render() {
    const { email, password, errorMessage } = this.state;
    return (
      <div>
        <div
          className=""
          style={{
            padding: "50px",
            background: "#FDFCDC",
            minHeight: "81vh",
          }}
        >
          {/* Add your login form or Firebase authentication logic here */}
          <p
            className="h3 d-flex justify-content-center"
            style={{ color: "#0D1B2A" }}
          >
            Login
          </p>

          {/* login form */}

          <div className="d-flex justify-content-center">
            <form
              onSubmit={this.handleSubmit}
              className="p-4"
              style={{
                borderRadius: "10px",
                width: "300px",
                backgroundColor: "#F5F6F4",
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
              }}
            >
              {/* Email field */}
              <div className="form-group mb-3">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={email}
                  onChange={this.handleInputChange}
                  required
                  placeholder="Enter email"
                />
              </div>

              {/* Password field */}
              <div className="form-group mb-3">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={password}
                  onChange={this.handleInputChange}
                  required
                  placeholder="Enter password"
                />
              </div>

              {/* Error message */}
              {errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}

              {/* Submit button */}
              <button
                type="submit"
                className="btn w-100"
                style={{ backgroundColor: "#F5DD90", fontWeight: "600" }}
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function withNavigate(Component) {
  function Wrapper(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  }
  return Wrapper;
}

export default withNavigate(LoginPage);
