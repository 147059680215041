import React, { Component } from "react";
import {
  getFirestore,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import "../css/App.css";
import DOMPurify from "dompurify";

class SingleBlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: null, // Store the blog data here
      loading: true, // To show loading state
      error: null, // To show any error during fetching
    };
  }

  async componentDidMount() {
    const { slug } = this.props.params; // Access slug from URL params
    const db = getFirestore();
    const blogsCollection = collection(db, "blogs"); // Reference to the blogs collection
    const q = query(blogsCollection, where("slug", "==", slug)); // Query to search by slug

    try {
      const querySnapshot = await getDocs(q); // Execute the query
      if (!querySnapshot.empty) {
        // If we found a blog with the matching slug
        const blogSnap = querySnapshot.docs[0]; // Get the first document
        this.setState({ blog: blogSnap.data(), loading: false });
      } else {
        // No blog found with the provided slug
        this.setState({ error: "Blog not found", loading: false });
      }
    } catch (error) {
      console.error("Error fetching blog:", error);
      this.setState({ error: "Failed to fetch blog data", loading: false });
    }
  }

  render() {
    const { blog, loading, error } = this.state;

    if (loading) {
      return (
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Loading blog content...</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="error-container">
          <h3 className="h3 error-message">{error}</h3>
        </div>
      );
    }

    return (
      <div className="container">
        <div className="row">
          {/* main row */}
          {/* main blog content here */}
          <h1 className="h1" style={{ marginBottom: "10", marginTop: "30" }}>
            {blog.title}
          </h1>
          {blog.coverPhoto && (
            <div style={{ marginTop: 10 }}>
              <img
                src={blog.coverPhoto}
                alt={blog.title}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          )}
          <div className="col-md-8 blogView" style={{ marginTop: 10 }}>
            <div
              style={{ textAlign: "justify", marginTop: 10 }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(blog.content),
              }}
            />
            <hr />
            <p className="small" style={{ marginTop: "10" }}>
              Published At:{" "}
              {new Date(blog.createdAt.seconds * 1000).toLocaleDateString()}
            </p>
          </div>

          <div className="col-md-4">
            {/* I will add other stuff here later */}
          </div>
        </div>
      </div>
    );
  }
}

// Higher-order component to get `params` (like blogId)
function withRouter(Component) {
  return function Wrapper(props) {
    const params = useParams();
    return <Component {...props} params={params} />;
  };
}

export default withRouter(SingleBlogPage);
