import React from "react";
import Unauthorized from "../pages/401";

const ProtectedRoute = ({ user, children }) => {
  if (!user) {
    return <Unauthorized />; // Show 401 error instead of redirect
  }

  return children;
};

export default ProtectedRoute;
