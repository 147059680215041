import React, { Component } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import "../css/App.css";

class BlogsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [], // Store fetched blogs
    };
  }

  componentDidMount() {
    this.fetchPublishedBlogs();
  }

  // Fetch published blogs from Firestore
  fetchPublishedBlogs = async () => {
    const db = getFirestore();
    const q = query(
      collection(db, "blogs"),
      where("status", "==", "published")
    );
    const querySnapshot = await getDocs(q);
    const blogs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    this.setState({ blogs });
  };

  handleCardClick = (slug) => {
    const { navigate } = this.props;
    navigate(`/blogs/${slug}`);
  };

  render() {
    const { blogs } = this.state;

    return (
      <div>
        <div className="container mt-5">
          {blogs.length !== 0 ? (
            <h1 className="mb-4">My Blogs</h1>
          ) : (
            <div></div>
          )}

          <div className="row">
            {blogs.length === 0 ? (
              <p
                className="text-center text-muted"
                style={{ fontStyle: "italic" }}
              >
                No time to write blogs yet, but something awesome is on its way!
              </p>
            ) : (
              blogs.map((blog) => (
                <div
                  key={blog.id}
                  className="col-md-6 mb-4"
                  onClick={() => this.handleCardClick(blog.slug)} // Card click event
                  style={{ cursor: "pointer" }}
                >
                  <div className="card shadow-sm blog-card">
                    <div className="card-body">
                      <h3 className="card-title">{blog.title}</h3>
                      <p className="card-text">
                        {blog.content.replace(/<[^>]+>/g, "").substring(0, 100)}
                        ...
                      </p>
                      <p className="text-muted small">
                        Published on:{" "}
                        {new Date(
                          blog.createdAt.seconds * 1000
                        ).toLocaleDateString()}
                      </p>
                      {/* Keep the button */}
                      <Link
                        to={`/blogs/${blog.slug}`}
                        className="btn btn-primary"
                      >
                        Continue Reading
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    );
  }
}

// Higher-order component to use navigation
function withNavigation(Component) {
  return function Wrapper(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}

export default withNavigation(BlogsPage);
