import { v4 as uuidv4 } from 'uuid'; // Make sure to install and import the uuid package
import "./css/App.css";

export const createSafeURL = (text) => {
  // Replace spaces with hyphens and remove special characters
  const sanitizedText = text
    .toString()
    .toLowerCase() // convert to lowercase for uniformity in URLs
    .trim()
    .replace(/[^a-z0-9\s-]/g, "") // remove all non-alphanumeric characters except spaces and hyphens
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // replace multiple hyphens with a single one

  const uniqueID = uuidv4().slice(0, 8); // Generate UUID and take first 8 characters
  return `${sanitizedText}-${uniqueID}`;
};

export const showNotification = (message, messageState, duration = 3000) => {
  const notification = document.createElement("div");
  if (messageState === "error") {
    notification.className = "notification-error";
  } else {
    notification.className = "notification";
  }
  notification.innerText = message;

  document.body.appendChild(notification);

  // Show the notification
  setTimeout(() => {
    notification.classList.add("show");
  }, 100); // Small delay to ensure the CSS animation works

  // Hide the notification after the specified duration
  setTimeout(() => {
    notification.classList.remove("show");
    setTimeout(() => {
      document.body.removeChild(notification);
    }, 500); // Delay to allow the fade-out animation to complete
  }, duration);
};


