import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./css/App.css";
import Home from "./pages/Home";
import SingleBlogPage from "./pages/BlogView";
import LoginPage from "./pages/Login";
import BlogsPage from "./pages/Blogs";
import CreateBlogPage from "./pages/CreateBlog";
import Footer from "./component/Footer";
import { firebaseApp } from "./firebaseApp";
import Dashboard from "./pages/Dashboard";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ProtectedRoute from "./component/protectedRoute";
import NotFound from "./pages/404";
import Navigation from "./component/navigation";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowSize: window.innerWidth,
      currentUser: null, // Store authenticated user info
      loading: true, // Loading state while checking authentication
    };
  }

  componentDidMount() {
    // Firebase is already initialized here, but you can perform operations if needed
    console.log("Firebase initialized:", firebaseApp);
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({ currentUser: user, loading: false });
      } else {
        this.setState({ currentUser: null, loading: false });
      }
    });
  }

  render() {
    const { currentUser, loading } = this.state;

    if (loading) {
      return <div></div>; // Show loading indicator while checking auth status
    }
    return (
      <Router>
        <div className="d-flex flex-column min-vh-100">
          <Navigation />
          <div className="flex-grow-1">
            <Routes>
              {/* public routes */}
              {/* Route for homepage */}
              <Route path="/" element={<Home />} />
              {/* Route for login page */}
              <Route path="/login" element={<LoginPage />} />
              {/* Route for all blogs */}
              <Route path="/blogs" element={<BlogsPage />} />
              {/* Dynamic route for a single blog by blog ID */}
              <Route path="/blogs/:slug" element={<SingleBlogPage />} />

              {/* protected routes */}
              {/* Route for dashboard page */}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute user={currentUser}>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />

              {/* Route for creating a new blog */}
              <Route
                path="/dashboard/create-new"
                element={
                  <ProtectedRoute user={currentUser}>
                    <CreateBlogPage />
                  </ProtectedRoute>
                }
              />

              {/* 404 Route */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
