import React, { Component } from "react";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  collection,
  serverTimestamp,
  query,
  where,
} from "firebase/firestore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill editor CSS
import { createSafeURL, showNotification } from "../uti";
import "../css/App.css";
import { useNavigate, useSearchParams } from "react-router-dom";

class CreateBlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      author: "",
      content: "",
      slug: "",
      excerpt: "",
      createdAt: null,
      updatedAt: null,
      status: "draft", // Default blog status
      timeoutId: null, // Timer for auto-save on inactivity
      hasChanges: false, // Track if changes have been made
    };

    // Full Quill toolbar options
    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }], // Header levels
        ["bold", "italic", "underline", "strike"], // Toggle buttons for bold, italic, etc.
        [{ color: [] }, { background: [] }], // Color and background
        [{ script: "sub" }, { script: "super" }], // Subscript/Superscript
        ["blockquote", "code-block"], // Blockquote and code block
        [{ list: "ordered" }, { list: "bullet" }], // Ordered list, unordered list
        [{ indent: "-1" }, { indent: "+1" }], // Indent
        [{ direction: "rtl" }], // Text direction
        [{ align: [] }], // Text alignment
        ["link", "image", "video"], // Insert links, images, videos
        ["clean"], // Remove formatting
      ],
    };
  }

  componentDidMount() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      this.setState({ author: user.email }); // Automatically set author
    }

    let blogId = new URLSearchParams(window.location.search).get("id");

    if (blogId) {
      this.setState({ isEditing: true, blogId });
      this.fetchBlogData(blogId); // Fetch blog data if editing
    }
  }

  fetchBlogData = async (blogId) => {
    const db = getFirestore();
    const blogRef = doc(db, "blogs", blogId);
    const blogSnap = await getDoc(blogRef);

    if (blogSnap.exists()) {
      const blogData = blogSnap.data();
      console.log(blogData);
      this.setState({
        title: blogData.title,
        content: blogData.content,
        slug: blogData.slug,
        excerpt: blogData.excerpt,
        status: blogData.status,
        updatedAt: blogData.updatedAt,
        createdAt: blogData.createdAt,
      });
    } else {
      console.log("This blog doesn't exist!");
      showNotification("This blog doesn't exist!", "error", 1000);

      // Delay navigation to allow the notification to be displayed
      setTimeout(() => {
        this.props.navigate("*"); // Redirect to 404 page after 3 seconds
      }, 1000);
    }
  };

  // Function to format updatedAt to a human-readable time
  formatUpdatedAtTime = () => {
    const { updatedAt } = this.state;

    if (!updatedAt) return "Not saved yet"; // If updatedAt is not available, return default message

    // Convert Firestore timestamp to JS Date object if it's a Firestore timestamp
    const updatedAtDate = updatedAt.toDate ? updatedAt.toDate() : updatedAt;

    // Format date options for a human-readable format
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };

    // Return formatted date
    return updatedAtDate
      .toLocaleDateString("en-GB", options)
      .replace(",", " at");
  };

  // Reset inactivity timer on user activity
  resetInactivityTimer = () => {
    // Clear any existing timeout
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }

    // Set a new timeout for 5 seconds (5000 milliseconds)
    const newTimeoutId = setTimeout(() => {
      this.handleSave("draft"); // Auto-save as draft after inactivity
    }, 5000);

    // Save the new timeoutId in the state
    this.setState({ timeoutId: newTimeoutId });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;

    // Generate slug when title changes
    if (name === "title") {
      if (this.state.slug !== "") {
        const slug = createSafeURL(value); // Convert title to slug using slugify
        this.setState({ slug });
      }
    }

    this.setState({
      [name]: value,
      hasChanges: true, // Mark as changed
    });

    this.resetInactivityTimer(); // Reset inactivity timer on input
  };

  handleContentChange = (value) => {
    // Generate excerpt from the first 30 characters of the content
    const excerpt = value.replace(/<[^>]+>/g, "").slice(0, 30); // Strip HTML tags
    this.setState({
      content: value,
      excerpt,
      hasChanges: true, // Mark as changed
    });

    this.resetInactivityTimer(); // Reset inactivity timer on content change
  };

  handleSave = async (status) => {
    // Check if title and content are both empty
    if (this.state.title.length === 0 && this.state.content.length === 0) {
      return;
    }

    const db = getFirestore();
    const slug =
      this.state.slug || this.state.title.toLowerCase().replace(/\s+/g, "-"); // Generate slug if not provided

    const blogData = {
      title: this.state.title,
      author: this.state.author,
      content: this.state.content,
      excerpt: this.state.excerpt,
      slug: slug,
      createdAt: this.state.createdAt || serverTimestamp(),
      updatedAt: serverTimestamp(),
      status: status, // Save as draft or published
      coverPhoto: null, // Keep null for this phase
      photos: [], // Keep null or empty array
    };

    try {
      // Check if the blog ID exists (if we're editing an existing blog)
      if (this.state.blogId) {
        // If there's an existing blog ID, fetch the blog by ID
        const blogRef = doc(db, "blogs", this.state.blogId);
        const blogSnap = await getDoc(blogRef);

        if (blogSnap.exists()) {
          // If the blog exists, check its current status
          const existingBlogData = blogSnap.data();

          if (existingBlogData.status === "draft" && status === "draft") {
            // If it's a draft and we are saving as draft, update the draft
            await updateDoc(blogRef, blogData);
            this.setState({
              updatedAt: new Date(), // Update the last saved time
              status, // Update status after save
              hasChanges: false,
            });
            console.log("Draft updated successfully.");
            showNotification("Draft Saved!", "success", 3000);
          } else if (status === "published") {
            // If status is "published", open the publish modal
            this.openPublishModal();
          }
        }
      } else {
        // No blog ID means it's a new draft, so create a new document
        const newDocRef = await addDoc(collection(db, "blogs"), blogData);

        this.setState({
          blogId: newDocRef.id, // Save the new blog ID in the state
          updatedAt: new Date(), // Update the last saved time
          status, // Update status after save
          hasChanges: false,
        });
        console.log("Draft saved as new.");
        showNotification("Draft Saved!", "success", 3000);
      }
    } catch (error) {
      console.error("Error saving blog: ", error);
      showNotification("An error occurred! Check Log!", "error", 3000);
    }
  };

  // Function to open the modal when publishing
  openPublishModal = () => {
    // Logic to open the modal (trigger Bootstrap modal)
    const publishModal = new window.bootstrap.Modal(
      document.getElementById("publishModal")
    );
    publishModal.show();
  };

  // Handle the confirmation of publishing after the modal
  confirmPublish = async () => {
    const db = getFirestore();
    const blogRef = doc(db, "blogs", this.state.blogId); // Get the blog reference using the blog ID

    const updatedBlogData = {
      ...this.state, // Spread the existing blog data
      status: "published", // Update the status to published
      updatedAt: serverTimestamp(), // Set the updated timestamp
    };

    this.setState({ isPublishing: true }); // Set the spinner state to true

    try {
      // Update the blog to "published" status
      await updateDoc(blogRef, updatedBlogData);

      // Artificial delay for smoother UX
      setTimeout(() => {
        this.setState({ isPublishing: false }); // Remove the spinner after the process
        showNotification("Blog published successfully!", "success", 3000);

        // Hide the modal after publishing
        const publishModal = window.bootstrap.Modal.getInstance(
          document.getElementById("publishModal")
        );
        publishModal.hide();

        // Redirect to the dashboard after publishing
        setTimeout(() => {
          this.props.navigate("/dashboard");
        }, 1000); // Additional 1-second delay before redirect
      }, 2000); // Delay for 1 second after the publish completes
    } catch (error) {
      console.error("Error publishing blog:", error);
      showNotification("An error occurred during publishing!", "error", 3000);
      this.setState({ isPublishing: false }); // Reset the spinner state if there's an error
    }
  };

  render() {
    const lastSaved = this.formatUpdatedAtTime();
    return (
      <div>
        <div className="container">
          {/* Page Title */}
          <div
            className="row"
            style={{
              marginTop: "10px",
              marginRight: "10px",
              paddingTop: "10px",
            }}
          >
            <div className="col-md-6">
              <p className="h1">Write Something New</p>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-md-5"></div>
                <div className="col-md-5">
                  {lastSaved ? (
                    <p
                      className="small justify-right"
                      style={{
                        backgroundColor: "#f9c74f", // Yellow background
                        color: "#fff", // White text
                        padding: "5px 10px", // Add some padding
                        borderRadius: "5px", // Optional: Rounded corners
                        border: "1px solid #f9c74f", // Optional: Yellow border
                        display: "inline-block", // Keeps the background wrapping just around the text
                      }}
                    >
                      Blog last saved: {lastSaved}
                    </p>
                  ) : (
                    <p
                      className="small justify-right"
                      style={{
                        backgroundColor: "#ccc", // Gray background
                        color: "#fff", // White text
                        padding: "5px 10px", // Add some padding
                        borderRadius: "5px", // Optional: Rounded corners
                        border: "1px solid #ccc", // Optional: Gray border
                        display: "inline-block", // Keeps the background wrapping just around the text
                      }}
                    >
                      Blog has not been saved yet.
                    </p>
                  )}
                </div>

                <div className="col-md-2">
                  {this.state.status === "draft" && (
                    <button
                      type="button"
                      className="btn btn-info"
                      disabled
                      style={{
                        opacity: "0.1",
                      }}
                    >
                      Preview
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Blog Title */}
          <div className="form-group mb-3">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              value={this.state.title}
              onChange={this.handleInputChange}
              placeholder="Enter the blog title"
              required
            />
          </div>

          {/* Blog Content (Rich Text Editor) */}
          <div className="form-group mb-3" style={{ height: "50vh" }}>
            <label htmlFor="content">Content</label>
            <ReactQuill
              value={this.state.content}
              onChange={this.handleContentChange}
              theme="snow"
              placeholder="Write your blog content here..."
              modules={this.modules}
              style={{ height: "100%" }} // Make the editor fill the container height
            />
          </div>

          {/* Buttons */}
          <div
            className="form-group mb-3 text-end"
            style={{ marginTop: "100px" }}
          >
            {/* Save as Draft button */}
            <button
              type="button"
              className="btn btn-warning me-2"
              onClick={() => this.handleSave("draft")}
              disabled={
                !this.state.hasChanges ||
                this.state.title.length === 0 ||
                this.state.content.length === 0
              } // Disable if no changes or if both title and content are empty
            >
              Save as Draft
            </button>

            {/* Publish Blog button */}
            {this.state.status === "draft" && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.handleSave("published")}
                disabled={
                  this.state.title.length === 0 ||
                  this.state.content.length === 0
                } // Disable if title and content are empty
              >
                Publish Blog
              </button>
            )}
          </div>

          {/* Modal */}
          <div
            className="modal fade"
            id="publishModal"
            tabIndex="-1"
            aria-labelledby="publishModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="publishModalLabel">
                    Confirm Publish
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="slug">Edit Slug</label>
                    <input
                      type="text"
                      className="form-control"
                      id="slug"
                      value={this.state.slug}
                      onChange={(e) => this.setState({ slug: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="excerpt">Edit Excerpt</label>
                    <textarea
                      className="form-control"
                      id="excerpt"
                      rows="3"
                      value={this.state.excerpt}
                      onChange={(e) =>
                        this.setState({ excerpt: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>

                  {/* Button with spinner */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.confirmPublish}
                    disabled={this.state.isPublishing} // Disable when publishing
                  >
                    {this.state.isPublishing ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        &nbsp; Publishing...
                      </>
                    ) : (
                      "Confirm Publish"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function withNavigate(Component) {
  return function Wrapper(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}

export default withNavigate(CreateBlogPage);
