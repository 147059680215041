import React, { Component } from "react";

import Intro from "../component/Intro";
import Speciality from "../component/Speciality";
import Contact from "../component/Contact";

// *--------------*
// mobile view
// *--------------*
import MobileNavigation from "../mobileview/component/NavMobile";
// import FooterMobile from "../mobileview/component/FooterMobile";
import IntroMobile from "../mobileview/component/IntroMobile";

class Home extends Component {
  constructor(props) {
    super(props);
    // Initialize the state
    this.state = {
      windowSize: window.innerWidth,
    };
  }

  // This function will update windowSize in the state whenever the window is resized
  updateWindowSize = () => {
    this.setState({
      windowSize: window.innerWidth,
    });
  };

  // Add event listener when the component mounts
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowSize);
    // Set initial state based on the current window size
    this.updateWindowSize();
  }

  // Remove event listener when the component unmounts to avoid memory leaks
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowSize);
  }

  render() {
    const isMobileView = this.state.windowSize <= 1100;

    if (isMobileView) {
      return (
        <div>
          <MobileNavigation />
          <IntroMobile />
          {/* <FooterMobile /> */}
        </div>
      );
    }

    return (
      <div>
        <Intro />
        <Speciality />
        <Contact />
      </div>
    );
  }
}

export default Home;
