import React, { Component } from "react";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs, updateDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { FaTrashAlt, FaEllipsisV } from "react-icons/fa"; // For delete icon and dot menu
import { Dropdown } from 'bootstrap';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: null,
      currentTime: new Date().toLocaleTimeString(),
      draftBlogs: [], // Store fetched draft blogs
      publishedBlogs: [], // Store fetched published blogs
      blogToDelete: null, // Store the blog ID for the delete action
    };
  }

  componentDidMount() {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      this.setState({ userEmail: user.email });
    }

    // Update time every second
    this.intervalID = setInterval(() => {
      this.setState({ currentTime: new Date().toLocaleTimeString() });
    }, 1000);

    // Load draft and published blogs
    this.loadDraftBlogs();
    this.loadPublishedBlogs();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  handleCreateNewBlog = () => {
    this.props.navigate('/dashboard/create-new');
  };


  // Fetch draft blogs from Firestore
  loadDraftBlogs = async () => {
    const db = getFirestore();
    const q = query(collection(db, "blogs"), where("status", "==", "draft"));
    const querySnapshot = await getDocs(q);
    const draftBlogs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    this.setState({ draftBlogs });
  };

  // Fetch published blogs from Firestore
  loadPublishedBlogs = async () => {
    const db = getFirestore();
    const q = query(collection(db, "blogs"), where("status", "==", "published"));
    const querySnapshot = await getDocs(q);
    const publishedBlogs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    this.setState({ publishedBlogs });
  };

  // Open modal to confirm delete
  openDeleteModal = (blogId) => {
    this.setState({ blogToDelete: blogId });
    const modal = new window.bootstrap.Modal(document.getElementById("deleteModal"));
    modal.show();
  };

  // Handle confirmed delete action
  handleDeleteBlog = async () => {
    const { blogToDelete } = this.state;
    const db = getFirestore();
    const blogRef = doc(db, "blogs", blogToDelete);

    try {
      await updateDoc(blogRef, {
        status: "deleted",
      });
      // Remove the deleted blog from the view
      this.setState({
        draftBlogs: this.state.draftBlogs.filter((blog) => blog.id !== blogToDelete),
        publishedBlogs: this.state.publishedBlogs.filter((blog) => blog.id !== blogToDelete),
        blogToDelete: null, // Reset blog to delete
      });
      const modal = window.bootstrap.Modal.getInstance(document.getElementById("deleteModal"));
      modal.hide(); // Hide the modal after deleting
    } catch (error) {
      console.error("Error deleting blog: ", error);
    }
  };

  handleEditBlog = (blogId) => {
    this.props.navigate(`/dashboard/create-new?id=${blogId}`);
  };

  render() {
    const { userEmail, currentTime, draftBlogs, publishedBlogs } = this.state;

    return (
      <div>

        <div className="container">
          {/* Display logged-in user's email and current time */}
          <div className="row my-3">
            <div className="col-md-4" >
              <p className="small">
                <i>Logged in as {userEmail}</i>
                <br />
                It's now: {currentTime}
              </p>
            </div>

            <div className="col-md-8 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={this.handleCreateNewBlog}
              >
                Create new Blog!
              </button>
            </div>
          </div>

          {/* Main content: left 40%, right 60% */}
          <div className="row" style={{ backgroundColor: "#E1F2FE" }} >
            {/* Left column (40% width) - Published Blogs */}
            <div className="col-6">
              <div
                className="row"
                style={{
                  overflowY: "auto", // Allows scrolling for content if it overflows
                  padding: "10px",
                }}
              >
                <h3 style={{ marginTop: 10 }}>Published Blogs</h3>
                {publishedBlogs.length === 0 ? (
                  <p>No published blogs available.</p>
                ) : (
                  publishedBlogs.map((blog, index) => (
                    <div
                      key={blog.id}
                      onClick={() => window.open(`/blogs/${blog.slug}`, "_blank")} // Open in a new tab
                      className="d-flex justify-content-between align-items-center mb-2"
                      style={{
                        cursor: "pointer", // Change cursor to indicate clickability
                        backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff", // Alternate colors
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#e0e0e0"; // Highlight on hover
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = index % 2 === 0 ? "#f9f9f9" : "#ffffff"; // Revert to original color
                      }}
                    >
                      <div>
                        <strong>{blog.title}</strong>
                        <br />
                        <small>{blog.excerpt}</small>
                      </div>

                      <div className="dropdown">
                        <button
                          className="btn btn-link text-secondary"
                          id={`dropdownMenuButton-${blog.id}`}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={(e) => {
                            e.stopPropagation();
                          console.log("Registering..")}} // Prevents dropdown click from triggering blog open
                        >
                          <FaEllipsisV />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${blog.id}`}>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent dropdown click from triggering blog open
                                this.handleEditBlog(blog.id);
                              }}
                            >
                              Edit
                            </button>
                          </li>
                          <li>
                            <button
                              className="dropdown-item text-danger"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent dropdown click from triggering blog open
                                this.openDeleteModal(blog.id);
                              }}
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            {/* Right column (60% width) */}
            <div className="col-6">
              {/* Right Upper (60%, 50%) - Draft blogs */}
              {/* Right Upper (60%, 50%) - Draft blogs */}
              <div
                className="row"
                style={{
                  overflowY: "auto", // Allows scrolling for content if it overflows
                  padding: "10px",
                }}
              >
                <h3>Draft Blogs</h3>
                {draftBlogs.length === 0 ? (
                  <p>No draft blogs available.</p>
                ) : (
                  // Sort by recent updatedAt and slice to show only 10 items
                  draftBlogs
                    .sort((a, b) => b.updatedAt.seconds - a.updatedAt.seconds) // Sort by most recent
                    .map((blog, index) => (
                      <div
                        key={blog.id}
                        onClick={() => this.handleEditBlog(blog.id)} // Navigate to edit blog page
                        className="d-flex justify-content-between align-items-center mb-2"
                        style={{
                          cursor: "pointer", // Change cursor to indicate clickability
                          backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff", // Alternate colors
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "#e0e0e0"; // Highlight on hover
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = index % 2 === 0 ? "#f9f9f9" : "#ffffff"; // Revert to original color
                        }}
                      >
                        {/* Title Section */}
                        <div className="text-truncate" style={{ flex: 1, marginRight: "10px" }}>
                          <strong>{blog.title}</strong>
                        </div>

                        {/* Last Updated Section */}
                        <div className="text-nowrap" style={{ marginRight: "10px" }}>
                          <small>Last updated: {new Date(blog.updatedAt.seconds * 1000).toLocaleString()}</small>
                        </div>

                        {/* Delete Icon Section */}
                        <div>
                          <button
                            className="btn btn-link text-danger"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the delete button click from navigating to the edit page
                              this.openDeleteModal(blog.id);
                            }}
                            title="Delete Blog"
                          >
                            <FaTrashAlt />
                          </button>
                        </div>
                      </div>
                    ))
                )}
              </div>


              {/* Right Lower (60%, 50%) - Analytics
              <div
                className="row"
                style={{
                  height: "50%",
                  backgroundColor: "#f8f9fa", // Light background color
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px dashed #6c757d", // Dashed border to indicate construction
                  borderRadius: "10px",
                  textAlign: "center",
                  padding: "20px",
                  color: "#6c757d", // Muted text color
                }}
              >
              <div style={{ fontSize: "50px", marginBottom: "10px" }}>
                  🚧 
                </div>
                <h3 style={{ fontWeight: "bold" }}>Analytics Coming Soon!</h3>
                <p>We're working hard to bring this feature to you. Stay tuned!</p>
              </div> 
                {/* Optional: Add an icon or image */}

            </div>
          </div>
        </div>

        {/* Delete Confirmation Modal */}
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex="-1"
          aria-labelledby="deleteModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="deleteModalLabel">Confirm Delete</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                Are you sure you want to delete this blog? This action cannot be undone.
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-danger" onClick={this.handleDeleteBlog}>Yes, Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// withNavigate HOC to access navigation (useNavigate alternative for class-based components)
function withNavigate(Component) {
  return function Wrapper(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}

export default withNavigate(Dashboard);
